import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _184f6e61 = () => interopDefault(import('../src/pages/cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _7a7af439 = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _149c3d04 = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _48ec013e = () => interopDefault(import('../src/pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _4d768fde = () => interopDefault(import('../src/pages/receipt.vue' /* webpackChunkName: "pages/receipt" */))
const _fcceb91c = () => interopDefault(import('../src/pages/search.vue' /* webpackChunkName: "pages/search" */))
const _5b5181fe = () => interopDefault(import('../src/pages/search-booking/index.vue' /* webpackChunkName: "pages/search-booking/index" */))
const _3a76a9be = () => interopDefault(import('../src/pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _2d47549c = () => interopDefault(import('../src/pages/travel-extras.vue' /* webpackChunkName: "pages/travel-extras" */))
const _12a2b836 = () => interopDefault(import('../src/pages/unsupported-browser.vue' /* webpackChunkName: "pages/unsupported-browser" */))
const _640ca781 = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _42533c84 = () => interopDefault(import('../src/pages/manage-booking/modify.vue' /* webpackChunkName: "pages/manage-booking/modify" */))
const _8e52da38 = () => interopDefault(import('../src/pages/manage-booking/receipt.vue' /* webpackChunkName: "pages/manage-booking/receipt" */))
const _8de3df7c = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _0236305d = () => interopDefault(import('../src/pages/select-extras/product/index.vue' /* webpackChunkName: "pages/select-extras/product/index" */))
const _08d037b2 = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _7240d4f8 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cookie-policy",
    component: _184f6e61,
    name: "cookie-policy"
  }, {
    path: "/maintenance",
    component: _7a7af439,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _149c3d04,
    name: "manage-booking"
  }, {
    path: "/privacy-policy",
    component: _48ec013e,
    name: "privacy-policy"
  }, {
    path: "/receipt",
    component: _4d768fde,
    name: "receipt"
  }, {
    path: "/search",
    component: _fcceb91c,
    name: "search"
  }, {
    path: "/search-booking",
    component: _5b5181fe,
    name: "search-booking"
  }, {
    path: "/terms-and-conditions",
    component: _3a76a9be,
    name: "terms-and-conditions"
  }, {
    path: "/travel-extras",
    component: _2d47549c,
    name: "travel-extras"
  }, {
    path: "/unsupported-browser",
    component: _12a2b836,
    name: "unsupported-browser"
  }, {
    path: "/manage-booking/cancel",
    component: _640ca781,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/modify",
    component: _42533c84,
    name: "manage-booking-modify"
  }, {
    path: "/manage-booking/receipt",
    component: _8e52da38,
    name: "manage-booking-receipt"
  }, {
    path: "/manage-booking/update-details",
    component: _8de3df7c,
    name: "manage-booking-update-details"
  }, {
    path: "/select-extras/product",
    component: _0236305d,
    name: "select-extras-product"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _08d037b2,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _7240d4f8,
    name: "index"
  }, {
    path: "/dedicated",
    component: _7240d4f8,
    meta: {"auth0Flow":"dedicated"},
    name: "dedicated-wl-index"
  }, {
    path: "/dedicated/search",
    component: _fcceb91c,
    meta: {"auth0Flow":"dedicated"},
    name: "dedicated-wl-search"
  }, {
    path: "/dedicated/select-extras/product",
    component: _0236305d,
    meta: {"auth0Flow":"dedicated"},
    name: "dedicated-wl-select-extras"
  }, {
    path: "/dedicated/manage-booking/modify",
    component: _42533c84,
    meta: {"auth0Flow":"dedicated"},
    name: "dedicated-wl-modify"
  }, {
    path: "/dedicated/receipt",
    component: _4d768fde,
    meta: {"auth0Flow":"dedicated"},
    name: "dedicated-wl-receipt"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
